@import 'mvg';
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

$padding: 16px;
$borderradius: 8px;
$titlefont: "Lilita One", sans-serif;
$breadFont: "Arial";
$condensedFont: "Arial";

$accent1: green;
$accent2: #316cd7;

$maxWidthApp: 1000px;

* {
    font-family: $titlefont;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
    font-family: $titlefont;
    font-weight: 400;
}

button {
    font-family: $titlefont;
    font-weight: 400;
    background-color: $accent1;
    border: 2px solid darken($accent1,10);
    border-color: darken($accent1, 10);
    box-shadow: -3px 3px 0px 0px darken($accent1,10);
    &[disabled]{
        border: solid 2px gray;
    }
}
input[type="number"], input[type="text"], [contenteditable] {
    border: 2px solid black;
    box-shadow: -3px 3px 0px 0px black;
    &:focus-visible {
        outline: none;
    }
}

.App {
    background-color: --color-bg-secondary;
    user-select: none;
    margin: auto;
    max-width: $maxWidthApp;
    .mainContainer {
        max-width: $maxWidthApp;
    }
}

[contenteditable] {
    border: 2px solid black;
    background-color: white;
    border-radius: $borderradius;
    padding: 10px;
}

.hidden {
    display: none;
}

.inputButton {
    width: 100%;
}

.mainContainer {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    padding: $padding;
    &.SingleGame {
        margin-top: -48px;
        padding-bottom: 108px;
    }
}

.error {
    border: 2px solid red;
}

//Nav;
.headerSpacer {
    height: 48px;
    button {
        background: none;
        border: none;
        padding: 4px 8px 0 8px;
        margin: 0px;
        border-radius: 0px 8px 8px 0px;
        color: black;
        box-shadow: unset;
        > span {
            font-size: 31pt;
            padding: 6px;
        }
    }
}

.editSetting {
    $buttonWidth: 100px;
    position: relative;

    [contenteditable], input {
        width: calc(100% - $buttonWidth);
        max-width: unset;
        border: 2px solid;
        border-right: 0px;
        border-radius: $borderradius 0 0 $borderradius;
        position: relative;
        z-index: 1;
        &:focus-visible {
            outline: unset;
            border-color: $accent1;
            box-shadow: -3px 3px 0px 0px $accent1;
        }
    }

    button {
        position: absolute;
        right: 0;
        top: 0;
        width: $buttonWidth;
        height: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0px $borderradius $borderradius 0px;
        text-transform: lowercase;
        box-shadow: -3px 3px darken($accent1,10);
        &.disabledButton {
            background-color: lightgray;
            border: 2px solid gray;
            color: gray;
            box-shadow: -3px 3px gray;
            cursor: not-allowed;
        }
        > span {
            margin-right: 10px;
        }
    }
}

.backIcon {
    width: 48px;
    height: 48px;
    position: relative;
    display: block;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 60%;
        height: 11px;
        background-color: black;
        transform-origin: left;
        top: 50%;
        left: 0px;
    }

    &::before {
        transform: rotate(-45deg);
        margin-top: 0px
    }

    &::after {
        transform: rotate(45deg);
        margin-top: -8px
    }
}

.gamesContainer {
    .gameRow {
        display: block;
        color: black;
        text-decoration: none;
        padding: 10px 0;
        > h2 {
            display: inline-block;
            width: calc(100% - 40px);
        }
        > span {
            width: 40px;
            font-size: 26pt;
            font-weight: 900;
            float: right;
        }
    }
}
.newgame {
    > span {
        float: right;
        margin-left: 10px;
        font-weight: 900;
    }
}
.logout {
    float: right;
    background-color: white;
    color: $accent1;
    border: 2px solid $accent1;
    box-shadow: -3px 3px 0 0 $accent1;
}
.gamesSubNavigation {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: calc(100vw - ($padding * 2));
    background: rgb(255, 255, 255);
    position: fixed;
    padding: 0;
    margin: 0;
    left: $padding;
    bottom: $padding;
    border-radius: $borderradius;
    overflow: hidden;
    border: 2px solid black;

    a {
        height: 64px;
        width: 100%;
        text-align: center;
        font-size: 14pt;
        padding: 13px 0;
        color: black;
        background: white;
        text-decoration: none;

        &.set {
            background: black;
            color: white;
        }

        border-right: 2px solid black;

        &:last-of-type {
            border-right: none;
        }
    }
}

// setting
.newPlayerHolder {
    margin-top: $padding*2;
    padding: $padding;
    background-color: lighten(lightgray, 10);
    border-radius: $padding;
}
.newPlayerTable {
    width: 100%; 
    border: 0px;
    tr {
        background-color: unset !important;
    }
    td {
        padding: 0;
        border-bottom: 1px solid;
    }
    td:first-of-type {
        min-width: 200px;
        font-size: 20pt;
        padding-left: 20px;
    }
    button {
        background: none;
        border: none;
        color: red;
        padding: 0;
        box-shadow: unset;
    }
}
.addNewPlayer {
    height: 56px;
    .material-symbols-outlined {
        float: right;
        font-weight: 900;
    }
    >input {
        height: 100%;
    }
}
.settings {
    label {
        font-weight: 400;
        margin-top: $padding*2;
    }
}

.pickLoser {
    margin-top: $padding;
}
.pickLoser,
.pickBattlers {

    .loserHolder,
    .battlersHolder {
        border: 1px solid black;
        border-radius: $borderradius;
        width: 100%;
        display: flex;
        align-items: stretch;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        font-family: $titlefont;

        > span {
            display: block;
            border: 1px solid black;
            border-radius: $borderradius;
            background-color: white;
            color: black;
            font-size: 16pt;
            padding: $padding/2 $padding*1.5;
            margin: $padding/2;
            box-shadow: -3px 3px 0px 0px black;

            &[data-selected="true"] {
                background-color: $accent2;
                color: white;
            }
        }
    }
    .lowScore {
        margin-top: $padding*2;
        margin-bottom: $padding*2;
        input {
            border: 2px solid black;
        }
    }
}

.mexicanScore {
    display: flex;
    flex-wrap: wrap;

    label {
        width: 100%;
    }

    .mexicanScoreHolder {
        width: 50%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;

        .mexicanScoreMin,
        .mexicanScoreAdd,
        .mexicanScore {
            font-size: 30pt;
            // padding: $padding;
        }
    }
}

.battleButton {
    margin-top: $padding*2;
    button {
        width: 100%;
        border: 2px solid $accent1;
        color: $accent1;
        background-color: white;
        padding: 10px;
        box-shadow: -3px 3px 0px 0px $accent1;
        .text {
            padding: 0px;
        }
        >span {
            &:first-of-type {
                margin-right: 20px;
            }
            &:last-of-type {
                margin-left: 20px;
            }
        }
    }
}
.heatButtons {
    button {
        margin-top: $padding*2;
        width: 100%;
        display: inline-block;
    }
}

.hiddenPopup {
    &.hidden {
        opacity: 0;
        display: none;
    }

    opacity: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    .content {
        width: calc(100% - ($padding * 2));
        margin: $padding;
        padding: $padding;
        background-color: white;
    }

    .battleDraw {
        border-bottom: 1px solid black;
        > span {
            float: right;
        }
    }

    .versus {
        display: flex;
        flex-wrap: wrap;
        min-height: 100px;
        position: relative;

        .vsIcon {
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            >span,
            >img {
                text-align: center;
                font-weight: 800;
                font-size: 26pt;
            }
        }

        .versusPlayer {
            width: 50%;
            padding: 0 40px;
            z-index: 2;

            span {
                display: block;
                text-align: center;
                width: 100%;
            }

            input {
                width: 100%;
            }
        }
    }
}
.popupLoser {
    span { 
        color: $accent2;
        font-size: 2.7em;
    }
}

.statsTable {
    table {
        // width: 100%;
        max-width: 800px;
        display: block;
        margin: auto;
        border-collapse: collapse;
        thead, th {
            background-color: unset;
            color: black;
        }

        thead,
        tbody {
            width: 100%;
            // display: block;
        }

        tr {
            width: 100%;
            border: 2px solid black;
            background: none;
            // display: flex;
            td,
            th {
                // width: 25%;
                text-align: left;
            }
        }
    }

    .scoreCell {
        position: relative;

        .sombrero {
            display: inline-block;
            position: relative;
            height: 15px;
            width: 20px;
            margin-top: 5px;
            margin-left: 3px;

            .sombreroImg {
                position: absolute;
                width: 22px;
            }

            >span {
                position: absolute;
                top: 12px;
                font-size: 8pt;
                font-weight: 900;
                width: 100%;
                text-align: center;
            }
        }

        .battleSetHolder {
            display: inline-block;
            margin-bottom: -30px;
        }

        .battleSet {
            // float: right;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 32px;
            border-radius: 6px;
            box-sizing: initial;
            padding: 2px;

            &.boxed {
                border: 2px solid black;
                margin: 0px 0 -14px 2px;
                position: relative;
                top: -6px;
            }

            .battleIcon {
                display: contents;

                img {
                    width: 16px;
                }
            }
        }
    }
}

.tallyLine {
    width: 2px;
    height: 14px;
    background-color: rgb(0, 0, 0);
    display: inline-block;
    margin: 0 1px;

    &.crossed {
        margin: 0px 12px -5px -9px;
        height: 23px;
    }
}

.mainContainer {
    >h2 {
        width: 100%;
        text-align: center;
    }
}